@import "https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap";
html {
  height: 100%;
}

body {
  min-height: 100%;
  flex-direction: column;
  align-items: stretch;
  font-family: Roboto;
  display: flex;
}

body > :first-child {
  flex-direction: column;
  flex-grow: 1;
  align-items: stretch;
  display: flex;
}

._Jhdyq_container {
  pointer-events: none;
  justify-content: center;
  align-items: center;
  display: flex;
  position: fixed;
  inset: 0;
}

._Jhdyq_container ._Jhdyq_paper {
  min-width: 128px;
  background-color: #fff;
  border-radius: 8px;
  padding: 8px 16px;
  box-shadow: 0 0 16px 2px #000000bf;
}

._Jhdyq_container ._Jhdyq_paper h2 {
  color: #323232;
  text-align: center;
}

._Jhdyq_container ._Jhdyq_paper ._Jhdyq_barContainer {
  width: 100%;
  margin: 8px 0;
}

._Jhdyq_container ._Jhdyq_paper ._Jhdyq_barContainer ._Jhdyq_green {
  background-color: #00cc87;
}

._Jhdyq_container ._Jhdyq_paper ._Jhdyq_barContainer ._Jhdyq_red {
  background-color: #cc0047;
}

._Jhdyq_container ._Jhdyq_paper ._Jhdyq_barContainer ._Jhdyq_gray {
  background-color: #323232;
}

._Jhdyq_container ._Jhdyq_paper ._Jhdyq_barContainer > * {
  min-height: 16px;
  display: inline-block;
}

.FV1yrG_container {
  flex-direction: row;
  flex-grow: 1;
  justify-content: space-between;
  display: flex;
}

@media only screen and (min-width: 0) and (max-width: 999px) {
  .FV1yrG_container {
    flex-direction: column;
  }
}

.FV1yrG_container .FV1yrG_half {
  color: #fff;
  text-shadow: 2px 2px 4px #000;
  cursor: pointer;
  user-select: none;
  flex-direction: column;
  flex: 1;
  justify-content: center;
  align-items: center;
  font-family: Roboto;
  font-size: 64px;
  display: flex;
}

.FV1yrG_container .FV1yrG_half.FV1yrG_green {
  background-color: #00cc87;
}

.FV1yrG_container .FV1yrG_half.FV1yrG_red {
  background-color: #cc0047;
}

html {
  -webkit-text-size-adjust: 100%;
  line-height: 1.15;
}

body {
  margin: 0;
}

main {
  display: block;
}

h1 {
  margin: .67em 0;
  font-size: 2em;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

pre {
  font-family: monospace, monospace;
  font-size: 1em;
}

a {
  background-color: #0000;
}

abbr[title] {
  border-bottom: none;
  text-decoration: underline dotted;
}

b, strong {
  font-weight: bolder;
}

code, kbd, samp {
  font-family: monospace, monospace;
  font-size: 1em;
}

small {
  font-size: 80%;
}

sub, sup {
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

img {
  border-style: none;
}

button, input, optgroup, select, textarea {
  margin: 0;
  font-family: inherit;
  font-size: 100%;
  line-height: 1.15;
}

button, input {
  overflow: visible;
}

button, select {
  text-transform: none;
}

button, [type="button"], [type="reset"], [type="submit"] {
  -webkit-appearance: button;
}

button::-moz-focus-inner, [type="button"]::-moz-focus-inner, [type="reset"]::-moz-focus-inner, [type="submit"]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

button:-moz-focusring, [type="button"]:-moz-focusring, [type="reset"]:-moz-focusring, [type="submit"]:-moz-focusring {
  outline: 1px dotted ButtonText;
}

fieldset {
  padding: .35em .75em .625em;
}

legend {
  box-sizing: border-box;
  color: inherit;
  max-width: 100%;
  white-space: normal;
  padding: 0;
  display: table;
}

progress {
  vertical-align: baseline;
}

textarea {
  overflow: auto;
}

[type="checkbox"], [type="radio"] {
  box-sizing: border-box;
  padding: 0;
}

[type="number"]::-webkit-inner-spin-button, [type="number"]::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

details {
  display: block;
}

summary {
  display: list-item;
}

template, [hidden] {
  display: none;
}

/*# sourceMappingURL=index.ea3b2c54.css.map */
