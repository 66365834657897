@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap');

html {
  height: 100%;
}

body {
  font-family: Roboto;
  min-height: 100%;
  display: flex;
  align-items: stretch;
  flex-direction: column;

  & > *:first-child {
    flex-grow: 1;
    display: flex;
    align-items: stretch;
    flex-direction: column;
  }
}
